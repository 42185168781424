import React, { Suspense, useCallback, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import RouteTransition from "./components/common/commonUtilComponent/routeTransition/RouteTransition";

import theme from "./styles/Theme";
import "./i18n";
import ErrorBoundary from "./utils/ErrorBoundary";
import Scrollbar from "./components/common/commonUiComponent/scrollbar/Scrollbar";
import ViewHeight from "./components/common/commonUtilComponent/viewHeight/ViewHeight";
import TempLogin from "./pages/TempLogin";
import SnackBarProvider from "./components/common/commonUnitComponent/snackBar/SnackBarProvider";
import PageHelmet from "./components/common/commonUtilComponent/pageHelmet/PageHelmet";
import ServiceWorkerUpdater from "./components/common/commonUtilComponent/serviceWorkerUpdater/ServiceWorkerUpdater";
import PrivateRoute from "./components/common/commonUtilComponent/route/PrivateRoute";
import UserInfoProvider from "./components/common/commonUtilComponent/initUserInfoSection/UserInfoProvider";
import ModalProvider from "./components/common/modal/modalProvider/ModalProvider";
import ImageFullSizeViewProvider from "./components/common/commonUnitComponent/imageFullSizeView/ImageFullSizeViewProvider";
import SubHeader from "./components/common/commonUiComponent/subHeader/SubHeader";
import CoachMarkProvider from "./components/common/commonUnitComponent/coachMark/CoachMarkProvider";
import ChannelTalk from "./components/common/channelTalk/ChannelTalk";
import MobileBottomButtons from "./components/common/commonUiComponent/mobileBottomButtons/MobileBottomButtons";
import Header from "./components/common/commonUiComponent/header/Header";
import AdSaleManageScheduleContents from "./components/ad/adManageNew/adSaleManage/adSaleManageSchedule/AdSaleManageScheduleContents";
import AdSaleManageAdListTableContents from "./components/ad/adManageNew/adSaleManage/adSaleManageAdListTable/AdSaleManageAdListTableContents";
import AdSaleManageHistoryAnalysisTableContents from "./components/ad/adManageNew/adSaleManage/adSaleManageHistoryAnalysisTable/AdSaleManageHistoryAnalysisTableContents";
import AdBuyManageTemplatesContents from "./components/ad/adManageNew/adBuyManage/adBuyManageTemplates/AdBuyManageTemplatesContents";
import AdBuyManageAllAdOfferListTableContents from "./components/ad/adManageNew/adBuyManage/adBuyManageAllAdOfferListTable/AdBuyManageAllAdOfferListTableContents";
import AdBuyManageHistoryAnalysisTableContents from "./components/ad/adManageNew/adBuyManage/adBuyManageHistoryAnalysisTable/AdBuyManageHistoryAnalysisTableContents";
import {
    AD_MANAGE_BUY_ANALYSIS,
    AD_MANAGE_BUY_HISTORY,
    AD_MANAGE_BUY_TEMPLATES,
    AD_MANAGE_SALE_ANALYSIS,
    AD_MANAGE_SALE_HISTORY,
    AD_MANAGE_SALE_SCHEDULE,
} from "./constants/PageRegExp";
import { ReactQueryDevtools } from "react-query/devtools";
import GlobalFontStyle from "./styles/GlobalFontStyle";
import { SCROLL_BAR_ROOT_TYPE } from "./components/common/commonUiComponent/scrollbar/ScrollbarType";
import WebPushSubscriber from "./components/common/commonUtilComponent/webPushSubscriber/WebPushSubscriber";
import A2HsChecker from "./components/common/commonUtilComponent/a2hsChecker/a2hsChecker";
import VersionChecker from "./components/common/commonUtilComponent/versionChecker/VersionChecker";
import { safeLazy } from "./components/common/commonUtilComponent/serviceWorkerUpdater/SafeLazy";

const AdHomePage = safeLazy(() => import("./pages/ad/AdHomePage"));
const WalletPointTokenPage = safeLazy(
    () => import("./pages/wallet/WalletPointTokenPage"),
);
const NotFoundPage = safeLazy(() => import("./pages/NotFoundPage"));
const LoginSuccessPage = safeLazy(
    () => import("./pages/auth/loginResult/LoginSuccessPage"),
);
const AdBidPage = safeLazy(() => import("./pages/ad/AdBidPage"));
const MarketHomePage = safeLazy(() => import("./pages/market/MarketHomePage"));
const MarketPointPage = safeLazy(
    () => import("./pages/market/MarketPointPage"),
);
const MarketProjectPage = safeLazy(
    () => import("./pages/market/MarketProjectPage"),
);
const WalletCollectionPage = safeLazy(
    () => import("./pages/wallet/WalletCollectionPage"),
);
const MyProfilePage = safeLazy(() => import("./pages/my/MyProfilePage"));
const MyPaymentMethodListPage = safeLazy(
    () => import("./pages/my/MyPaymentMethodListPage"),
);
const MyNotificationPage = safeLazy(
    () => import("./pages/my/MyNotificationPage"),
);
const MyPaymentsHistoryPage = safeLazy(
    () => import("./pages/my/MyPaymentsHistoryPage"),
);
const AdBidingPage = safeLazy(() => import("./pages/ad/AdBidingPage"));
const AdBidCompletePage = safeLazy(
    () => import("./pages/ad/AdBidCompletePage"),
);
const AdRegisterPage = safeLazy(() => import("./pages/ad/AdRegisterPage"));
const AdReRegisterPage = safeLazy(() => import("./pages/ad/AdReRegisterPage"));
const AdTemplateRegisterPage = safeLazy(
    () => import("./pages/ad/AdTemplateRegisterPage"),
);
const MarketItemPage = safeLazy(() => import("./pages/market/MarketItemPage"));
const WalletTokenMergePage = safeLazy(
    () => import("./pages/wallet/WalletTokenMergePage"),
);
const LoginPage = safeLazy(
    () => import("./components/common/commonUtilComponent/route/LoginPage"),
);
const MyWalletCollectionPage = safeLazy(
    () => import("./components/wallet/walletCollection/MyWalletCollectionPage"),
);
const SignUpPrivacyAgreePage = safeLazy(
    () => import("./pages/auth/SignUpPrivacyAgreePage"),
);
const LoginFailPage = safeLazy(
    () => import("./pages/auth/loginResult/LoginFailPage"),
);
const MarketProjectAddPage = safeLazy(
    () => import("./pages/market/MarketProjectAddPage"),
);
const MarketProjectAdjustPage = safeLazy(
    () => import("./pages/market/MarketProjectAdjustPage"),
);
const MarketItemAddPage = safeLazy(
    () => import("./pages/market/MarketItemAddPage"),
);
const MarketItemAdjustPage = safeLazy(
    () => import("./pages/market/MarketItemAdjustPage"),
);
const TestComponent = safeLazy(
    () => import("./components/common/testComponent/TestComponent"),
);
const MarketItemExaminePage = safeLazy(
    () => import("./pages/market/MarketItemExaminePage"),
);
const AdAdjustPage = safeLazy(() => import("./pages/ad/AdAdjustPage"));
const AdTemplateAdjustPage = safeLazy(
    () => import("./pages/ad/AdTemplateAdjustPage"),
);
const AdTemplateViewPage = safeLazy(
    () => import("./pages/ad/AdTemplateViewPage"),
);
const MyPaymentsAddSuccessPage = safeLazy(
    () => import("./pages/my/myPaymentAddResult/MyPaymentsAddSuccessPage"),
);
const MyPaymentsAddFailPage = safeLazy(
    () => import("./pages/my/myPaymentAddResult/MyPaymentsAddFailPage"),
);
const AdOfferTemplateViewPage = safeLazy(
    () => import("./pages/ad/AdOfferTemplateViewPage"),
);
const MyPayFailPage = safeLazy(
    () => import("./pages/my/myPayResult/MyPayFailPage"),
);
const IdentityVerifySuccessPage = safeLazy(
    () => import("./pages/auth/identityVerifyResult/IdentityVerifySuccessPage"),
);
const IdentityVerifyFailPage = safeLazy(
    () => import("./pages/auth/identityVerifyResult/IdentityVerifyFailPage"),
);
const MarketPointHistoryPage = safeLazy(
    () => import("./pages/market/MarketPointHistoryPage"),
);
const MarketItemHistoryPage = safeLazy(
    () => import("./pages/market/MarketItemHistoryPage"),
);
const MyPaymentsAddCancelPage = safeLazy(
    () => import("./pages/my/myPaymentAddResult/MyPaymentsAddCancelPage"),
);
const IntroPage = safeLazy(() => import("./pages/Home/IntroPage"));
const AdSpaceViewPage = safeLazy(() => import("./pages/ad/AdSpaceViewPage"));
const AdTemplateRegisterStepPage = safeLazy(
    () => import("./pages/ad/AdTemplateRegisterStepPage"),
);
const AdBuyingPage = safeLazy(() => import("./pages/ad/AdBuyingPage"));
const MyPaySuccessPage = safeLazy(
    () => import("./pages/my/myPayResult/MyPaySuccessPage"),
);
const AdTemplateCopyPage = safeLazy(
    () => import("./pages/ad/AdTemplateCopyPage"),
);
const AdSaleManagePage = safeLazy(() => import("./pages/ad/AdSaleManagePage"));
const AdBuyManagePage = safeLazy(() => import("./pages/ad/AdBuyManagePage"));

const HomePage = safeLazy(() => import("./pages/Home/HomePage"));

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: false,
            cacheTime: 60000,
            staleTime: 500,
            retry: 0,
        },
    },
});

function App() {
    const [, updateState] = useState<any>();
    const forceReRender = useCallback(() => updateState({}), []);

    return (
        <ThemeProvider theme={theme}>
            <Suspense>
                <GlobalFontStyle />
                <ErrorBoundary>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <VersionChecker />
                            <PageHelmet />
                            <ServiceWorkerUpdater />
                            <A2HsChecker />
                            <ViewHeight />
                            <ChannelTalk />
                            <WebPushSubscriber />
                            {/*<Push />*/}
                            <UserInfoProvider>
                                <Header />
                                <SubHeader />
                                <MobileBottomButtons />
                                <Scrollbar
                                    type={SCROLL_BAR_ROOT_TYPE}
                                    id={"root"}
                                    isSaveScrollTop={true}
                                >
                                    <RouteTransition>
                                        <Routes>
                                            <Route
                                                path={"/temp-login"}
                                                element={<TempLogin />}
                                            />
                                            {/*메인*/}
                                            <Route
                                                path="/"
                                                element={<HomePage />}
                                            />
                                            {/*소개*/}
                                            <Route
                                                path="/intro"
                                                element={<IntroPage />}
                                            />
                                            {/*광고 홈*/}
                                            <Route
                                                path="/ad"
                                                element={<AdHomePage />}
                                            />
                                            {/*광고 상세*/}
                                            <Route
                                                path="/ad/:ad_id/date/:selected_date"
                                                element={<AdBidPage />}
                                            />
                                            {/*광고 입찰*/}
                                            <Route
                                                path="/ad/:ad_id/date/:selected_date/bid"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdBidingPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 즉시 구매*/}
                                            <Route
                                                path="/ad/:ad_id/date/:selected_date/buy"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdBuyingPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 입찰 완료*/}
                                            <Route
                                                path="/ad/:ad_id/date/:selected_date/offer/:offer_id/complete"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdBidCompletePage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 판매 등록*/}
                                            <Route
                                                path="/ad/register/:campaign_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdRegisterPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 판매 재 등록 - 중단 후 재등록*/}
                                            <Route
                                                path="/ad/re-register/:ad_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdReRegisterPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 판매 수정*/}
                                            <Route
                                                path="/ad/adjust/:ad_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdAdjustPage />
                                                        }
                                                    />
                                                }
                                            />

                                            {/*광고 템플릿 보기*/}
                                            <Route
                                                path="/ad/template/:template_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdTemplateViewPage />
                                                        }
                                                    />
                                                }
                                            />

                                            {/*광고 템플릿 등록*/}
                                            <Route
                                                path="/ad/template-register/:campaign_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdTemplateRegisterPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 템플릿 등록 - step - 중첩라우팅 사용중 */}
                                            <Route
                                                path="/ad/template-register/:campaign_id/step/:step_no"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdTemplateRegisterStepPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 템플릿 등록 - step - space*/}
                                            <Route
                                                path="/ad/template-register/:campaign_id/step/:step_no/space/:space_no"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdTemplateRegisterStepPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 템플릿 복제*/}
                                            <Route
                                                path="/ad/template-copy/:template_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdTemplateCopyPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 템플릿 수정*/}
                                            <Route
                                                path="/ad/template-adjust/:template_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdTemplateAdjustPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 템플릿 보기 - 소유자*/}
                                            <Route
                                                path="/ad/template/:template_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdTemplateViewPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*입찰 템플릿 보기 - 판매자*/}
                                            <Route
                                                path="/ad/:ad_id/offer/:offer_id/template"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdOfferTemplateViewPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*광고 판매 관리*/}
                                            <Route
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdSaleManagePage />
                                                        }
                                                    />
                                                }
                                            >
                                                {/*광고 판매 관리 - 판매 일정*/}
                                                <Route
                                                    index // 기본
                                                    path={
                                                        AD_MANAGE_SALE_SCHEDULE
                                                    }
                                                    element={
                                                        <PrivateRoute
                                                            component={
                                                                <AdSaleManageScheduleContents />
                                                            }
                                                        />
                                                    }
                                                />
                                                {/*광고 판매 관리 - 판매 내역 - 판매 목록*/}
                                                <Route
                                                    path={
                                                        AD_MANAGE_SALE_HISTORY
                                                    }
                                                    element={
                                                        <PrivateRoute
                                                            component={
                                                                <AdSaleManageAdListTableContents />
                                                            }
                                                        />
                                                    }
                                                />
                                                {/*광고 판매 관리 - 판매 내역 - 성과 분석*/}
                                                <Route
                                                    path={
                                                        AD_MANAGE_SALE_ANALYSIS
                                                    }
                                                    element={
                                                        <PrivateRoute
                                                            component={
                                                                <AdSaleManageHistoryAnalysisTableContents />
                                                            }
                                                        />
                                                    }
                                                />
                                            </Route>
                                            {/*광고 구매 관리*/}
                                            <Route
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <AdBuyManagePage />
                                                        }
                                                    />
                                                }
                                            >
                                                {/*광고 구매 관리 - 내 광고 템플릿*/}
                                                <Route
                                                    path={
                                                        AD_MANAGE_BUY_TEMPLATES
                                                    }
                                                    element={
                                                        <PrivateRoute
                                                            component={
                                                                <AdBuyManageTemplatesContents />
                                                            }
                                                        />
                                                    }
                                                />
                                                {/*광고 구매 관리 - 구매내역 - 입찰 목록*/}
                                                <Route
                                                    path={AD_MANAGE_BUY_HISTORY}
                                                    element={
                                                        <PrivateRoute
                                                            component={
                                                                <AdBuyManageAllAdOfferListTableContents />
                                                            }
                                                        />
                                                    }
                                                />
                                                {/*광고 판매 관리 - 구매내역 - 성과 분석*/}
                                                <Route
                                                    path={
                                                        AD_MANAGE_BUY_ANALYSIS
                                                    }
                                                    element={
                                                        <PrivateRoute
                                                            component={
                                                                <AdBuyManageHistoryAnalysisTableContents />
                                                            }
                                                        />
                                                    }
                                                />
                                            </Route>
                                            {/*광고 direct url 로 보기*/}
                                            <Route
                                                path={
                                                    "/ad/brand/:brand_id/campaign/:campaign_id/space/:space_id/user/:user_id/view"
                                                }
                                                element={<AdSpaceViewPage />}
                                            />
                                            {/*플리마켓 홈*/}
                                            <Route
                                                path="/market"
                                                element={<MarketHomePage />}
                                            />
                                            {/*플리마켓 포인트부스*/}
                                            <Route
                                                path="/market/point/:point_id"
                                                element={<MarketPointPage />}
                                            />
                                            {/*플리마켓 프로젝트 리스트*/}
                                            <Route
                                                path="/market/:project_id"
                                                element={<MarketProjectPage />}
                                            />
                                            {/*플리마켓 아이템 상세*/}
                                            <Route
                                                path="/market/:project_id/:item_id"
                                                element={<MarketItemPage />}
                                            />
                                            {/*플리마켓 거래내역 > 포인트*/}
                                            <Route
                                                path={"/market/manage/point"}
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MarketPointHistoryPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*플리마켓 거래내역 > 아이템*/}
                                            <Route
                                                path={"/market/manage/item"}
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MarketItemHistoryPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*플리마켓 프로젝트 추가*/}
                                            <Route
                                                path="/market/add-project"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MarketProjectAddPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*플리마켓 프로젝트 수정*/}
                                            <Route
                                                path="/market/adjust-project/:project_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MarketProjectAdjustPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*플리마켓 아이템 추가*/}
                                            <Route
                                                path="/market/add-item"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MarketItemAddPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*플리마켓 아이템 수정*/}
                                            <Route
                                                path="/market/adjust-item/:project_id/:item_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MarketItemAdjustPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*플리마켓 아이템 출시*/}
                                            <Route
                                                path="/market/examine-item/:project_id/:item_id"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MarketItemExaminePage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*지갑 - 포인트&토큰*/}
                                            <Route
                                                path="/wallet"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <WalletPointTokenPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*지갑 - 토큰 합성*/}
                                            <Route
                                                path="/wallet/merge"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <WalletTokenMergePage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*지갑 - 컬렉션*/}
                                            <Route
                                                // 로그인 필요하지만 내부에서 로그인 처리해준다.
                                                // 외부에서 링크로 접근시 로그인 체크 안해야함.
                                                path="/my-wallet"
                                                element={
                                                    <MyWalletCollectionPage />
                                                }
                                            />
                                            {/*지갑 - 컬렉션*/}
                                            <Route
                                                path="/wallet/:user_id"
                                                element={
                                                    <WalletCollectionPage />
                                                }
                                            />
                                            {/*MY*/}
                                            <Route
                                                path="/my"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyProfilePage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*my - 결제수단관리*/}
                                            <Route
                                                path="/my/payments"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyPaymentMethodListPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*my - 결제수단 추가 성공*/}
                                            <Route
                                                path="/my/payments/success"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyPaymentsAddSuccessPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*my - 결제수단 추가 실패*/}
                                            <Route
                                                path="/my/payments/fail"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyPaymentsAddFailPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*my - 결제수단 추가 취소*/}
                                            <Route
                                                path="/my/payments/cancel"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyPaymentsAddCancelPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*/!*my - 단건 결제 성공*!/*/}
                                            <Route
                                                path="/my/pay/success"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyPaySuccessPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*my - 단건 결제 실패*/}
                                            <Route
                                                path="/my/pay/fail"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyPayFailPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*my - 알림설정*/}
                                            <Route
                                                path="/my/notification"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyNotificationPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*my - 유료 결제 내역*/}
                                            <Route
                                                path="/my/order-history"
                                                element={
                                                    <PrivateRoute
                                                        component={
                                                            <MyPaymentsHistoryPage />
                                                        }
                                                    />
                                                }
                                            />
                                            {/*인증 로그인 완료*/}
                                            <Route
                                                path="/auth/login/success"
                                                element={<LoginSuccessPage />}
                                            />
                                            {/*인증 로그인 실패*/}
                                            <Route
                                                path="/auth/login/fail"
                                                element={<LoginFailPage />}
                                            />
                                            {/*인증 로그인 신규*/}
                                            <Route
                                                path="/auth/privacy-agree"
                                                element={
                                                    <SignUpPrivacyAgreePage />
                                                }
                                            />
                                            {/*본인 인증 성공*/}
                                            <Route
                                                path="/auth/identity-verify/success"
                                                element={
                                                    <IdentityVerifySuccessPage />
                                                }
                                            />
                                            {/*본인 인증 실패*/}
                                            <Route
                                                path="/auth/identity-verify/fail"
                                                element={
                                                    <IdentityVerifyFailPage />
                                                }
                                            />
                                            {/*인증 로그인*/}
                                            <Route
                                                path="/login"
                                                element={<LoginPage />}
                                            />
                                            {process.env.NODE_ENV ===
                                                "development" && (
                                                <Route
                                                    path="/test"
                                                    element={<TestComponent />}
                                                />
                                            )}
                                            {/*not found*/}
                                            <Route
                                                path="*"
                                                element={<NotFoundPage />}
                                            />
                                            {/*not found*/}
                                            <Route
                                                path="not-found"
                                                element={<NotFoundPage />}
                                            />
                                        </Routes>
                                    </RouteTransition>
                                </Scrollbar>
                            </UserInfoProvider>
                            <ModalProvider />
                            <SnackBarProvider />
                            <CoachMarkProvider />
                            <ImageFullSizeViewProvider />
                        </BrowserRouter>
                        {process.env.NODE_ENV === "development" && (
                            <ReactQueryDevtools
                                initialIsOpen={false}
                                position="bottom-left"
                            />
                        )}
                    </QueryClientProvider>
                </ErrorBoundary>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;

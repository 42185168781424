import { ReactElement } from "react";
import styled from "styled-components";
import { AdSaleManageCalendarCellCommonProps } from "../../useGetAdSaleManageCalendarCellByCode";
import { numberToStringWithComma } from "../../../../../../../../../../utils/Utils";
import { useAdInfoQuery } from "../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdMyDailyDateData } from "../../../../../../../../../../hooks/ad/useGetAdMyDailyDateData";
import CalendarCellBackgroundRow from "../../../../../../../../../common/commonUiComponent/calendar/calendarCellRow/CalendarCellBackgroundRow";
import CalendarSelectedMobileCellNormalRow from "../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellNormalRow";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";
import { HiddenKeyManage } from "../../adSaleManageCalendarCellBody/AdSaleManageScheduleCalendarCellBody";

export const AdBidCalendarSelectedMobileCellBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdSaleManageCalendarSelectedMobileCellOnSchedule = (
    props: AdSaleManageCalendarCellCommonProps,
): ReactElement => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);

    const adInfo = rawAdData.advertisementInfo;
    const adSaleInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;

    const {
        targetDateInfo: cellInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdMyDailyDateData(props);

    return isTodaySale ? (
        // 당일 판매중 - 전체 판매 중단 상관X - 낙찰 표기
        cellInfo.offerSuccessInfo ? (
            <>
                <CalendarCellBackgroundRow
                    msg={"광고 게재중"}
                    type={"secondary"}
                />
                <CalendarSelectedMobileCellNormalRow
                    keyMsg={`${cellInfo.offerSuccessInfo.offer_success_user_nick}`}
                    valueMsg={`${numberToStringWithComma(
                        cellInfo.offerSuccessInfo.offer_price,
                    )} KRW`}
                />
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__dailySale__offerSuccessInfo__ms"}
                />
            </>
        ) : !isAdStop ? (
            // 당일 판매중 - 전체 판매중 - 낙찰X
            <>
                <CalendarCellBackgroundRow
                    msg={"즉시구매 가능"}
                    type={"blue"}
                />
                <CalendarSelectedMobileCellNormalRow
                    keyMsg={"즉시구매가"}
                    valueMsg={`${numberToStringWithComma(
                        adSaleInfo.ad_reserve_price * 1.1,
                    )} KRW`}
                />
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__notAdStop__dailySale__noOffer__ms"}
                />
            </>
        ) : (
            // 당일 판매중 - 전체 판매 중단 - 낙찰X
            <>
                <CalendarCellBackgroundRow msg={"판매 중단"} type={"normal"} />
                <HiddenKeyManage
                    id={"AD_ON_SCHEDULE__adStop__dailySale__noOffer__ms"}
                />
            </>
        )
    ) : !isAdStop ? (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <CalendarCellBackgroundRow msg={"판매 중단"} type={"danger"} />
            <HiddenKeyManage
                id={"AD_ON_SCHEDULE__notAdStop__notAdDailySale__noOffer__ms"}
            />
        </>
    ) : (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <CalendarCellBackgroundRow msg={"판매 중단"} type={"normal"} />
            <HiddenKeyManage
                id={"AD_ON_SCHEDULE__AdStop__notAdDailySale__noOffer__ms"}
            />
        </>
    );
};

export default AdSaleManageCalendarSelectedMobileCellOnSchedule;

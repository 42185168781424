import { ReactElement } from "react";
import styled from "styled-components";
import CalendarSelectedMobileCellRow from "./CalendarSelectedMobileCellRow";

export const CalendarCellMobileBlueRowContainer = styled.div``;

type Props = {
    keyMsg: string;
    valueMsg: string;
};

const CalendarSelectedMobileCellBlueRow = (
    props: Props,
): ReactElement | null => {
    return (
        <CalendarSelectedMobileCellRow
            {...props}
            keyStyle={{
                color: "#2E8EFF",
            }}
        />
    );
};

export default CalendarSelectedMobileCellBlueRow;

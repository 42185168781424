import { useQuery } from "react-query";
import { UserApi } from "../../apis/UserApi";
import { setCookie } from "../../utils/Utils";
import { useAtom } from "jotai";
import userInfoAtom from "../../store/userInfo/userInfoAtom";
import UserInfoAtom, { UserInfoType } from "../../store/userInfo/userInfoAtom";
import { useResetAtom } from "jotai/utils";
import { useLogout } from "../../hooks/common/useLogout";
import {
    getAuthKey,
    getIsLogin,
    resetAuthKey,
} from "../../store/auth/authKeyStore";

/**
 * 일반적인 경우 호출X
 * page 최초 접근 시에 user/status + authKey 통해서 user_idx 조회
 * authKey 없는 경우 초기화
 */

// api 명은 status 지만 info로 쓴다
export const useSetUserInfoQuery = () => {
    const [userInfo, setUserInfo] = useAtom(userInfoAtom);
    const resetUserInfo = useResetAtom(UserInfoAtom);
    const logout = useLogout();
    const queryResult = useQuery<UserInfoType | null, Error, UserInfoType>({
        retry: false,
        queryKey: ["userinfo-" + getAuthKey(), "userinfo"],
        queryFn: () => {
            return getIsLogin()
                ? UserApi.get(getAuthKey())
                : new Promise((resolve) => {
                      resolve(null);
                  });
        },

        useErrorBoundary: false,
        cacheTime: 0,
        suspense: false,
        onSuccess: (data) => {
            if (data !== null) setUserInfo(data);
            else {
                resetAuthKey();
                resetUserInfo();
                setCookie("authKey", "", -1, "/", ".saybuzz.io");
            }
        },
        onError: (err) => {
            // 강제 로그아웃 : 로그아웃 api 를 호출하는게 맞나? 모르겠음
            resetAuthKey();
            resetUserInfo();
            setCookie("authKey", "", -1, "/", ".saybuzz.io");
            // logout().then(() => {});
        },
    });
    return {
        isSuccess: queryResult.isSuccess,
        isFetched: queryResult.isFetched,
    };
};

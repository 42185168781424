import { AdDailyListItemStatusCodeType } from "../../../adDaily/AdDailyListItemType";

export type MyAdDailyListItemType = {
    ad_daily_status_code: AdDailyListItemStatusCodeType;
    ad_daily_status_name: string;
    ad_idx: string;
    ad_product_name: string;
    ad_reserve_price: number;
    ad_sales_date: string;
    available_offer_price: number;
    ad_stop_switch_bool: boolean;
    brand_id: string;
    brand_name: string;
    campaign_name: string;
    has_offer_bool: boolean;
    payment_adjust_status: string;
    adSaleInfo: {
        ad_reserve_price: number;
        ad_minimum_bid_price: number;
        ad_sales_daily_status: string; // 0 정상 ---- 1 중단
    };
    highestOfferInfo?: {
        offer_price: number;
        user_nick: string;
    };
    offerSuccessInfo?: {
        offer_idx: string;
        offer_price: number;
        offer_success_user_id: string;
        offer_success_user_idx: string;
        offer_success_user_nick: string;
        offer_success_user_influencer_bool: boolean;
    };
};

export const DEFAULT_MY_AD_DAILY_LIST_ITEM_TYPE_DATA: MyAdDailyListItemType = {
    adSaleInfo: {
        ad_reserve_price: 0,
        ad_minimum_bid_price: 0,
        ad_sales_daily_status: "",
    },
    ad_stop_switch_bool: false,
    ad_daily_status_code: "",
    ad_daily_status_name: "",
    ad_idx: "",
    ad_product_name: "",
    ad_reserve_price: 0,
    ad_sales_date: "",
    available_offer_price: 0,
    brand_id: "",
    brand_name: "",
    campaign_name: "",
    has_offer_bool: false,
    payment_adjust_status: "",
};

const QUICK_PRODUCTION_STATE_FLAG = false;

export const URL_CONSTANTS = {
    LOCAL_ASSET_URL:
        process.env.NODE_ENV === "development"
            ? QUICK_PRODUCTION_STATE_FLAG
                ? ""
                : ""
            : process.env.NODE_ENV === "stage"
            ? "//dev.saybuzz.io"
            : "//saybuzz.io",
    ASSET_URL:
        process.env.NODE_ENV === "development"
            ? QUICK_PRODUCTION_STATE_FLAG
                ? "//static.saybuzz.io/asset"
                : "//dev-static.saybuzz.io/asset"
            : process.env.NODE_ENV === "stage"
            ? "//dev-static.saybuzz.io/asset"
            : "//static.saybuzz.io/asset",
    API_URL:
        process.env.NODE_ENV === "development"
            ? QUICK_PRODUCTION_STATE_FLAG
                ? "//api.saybuzz.io"
                : "//devapi.saybuzz.io"
            : process.env.NODE_ENV === "stage"
            ? "//devapi.saybuzz.io"
            : "//api.saybuzz.io",
    S3_URL:
        process.env.NODE_ENV === "development"
            ? QUICK_PRODUCTION_STATE_FLAG
                ? "//static.saybuzz.io"
                : "//dev-static.saybuzz.io"
            : process.env.NODE_ENV === "stage"
            ? "//dev-static.saybuzz.io"
            : "//static.saybuzz.io",

    EXTERNAL_AD_URL:
        process.env.NODE_ENV === "development"
            ? QUICK_PRODUCTION_STATE_FLAG
                ? "//ad.saybuzz.io"
                : "//devapi-ad.saybuzz.io"
            : process.env.NODE_ENV === "stage"
            ? "//devapi-ad.saybuzz.io"
            : "//ad.saybuzz.io",

    PAGE_URL:
        process.env.NODE_ENV === "development"
            ? QUICK_PRODUCTION_STATE_FLAG
                ? "//saybuzz.io"
                : "//dev.saybuzz.io"
            : process.env.NODE_ENV === "stage"
            ? "//dev.saybuzz.io"
            : "//saybuzz.io",
};

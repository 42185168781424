import React, { ReactElement, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside, useWindowSize } from "usehooks-ts";
import { useAtom, useSetAtom } from "jotai";
import viewHeightAtom from "../../../../../store/scroll/viewHeightAtom";
import { ResponsiveWidth } from "../../../../../styles/Theme";
import { ModalLayoutWrapper } from "./ModalLayout";
import "../transition/modal-transition.css";
import ModalComponentInfoListAtom from "../../../../../store/ui/ModalComponentInfoListAtom";
import isScrollLockAtom from "../../../../../store/scroll/isScrollLockAtom";

export const BottomSheetLayoutWrapper = styled.div``;

export const BottomSheetLayoutBackground = styled(ModalLayoutWrapper)<{
    vh: number;
    isTabletMBottomSheet: boolean;
}>`
    -webkit-overflow-scrolling: touch;

    @media ${({ theme, isTabletMBottomSheet }) =>
            isTabletMBottomSheet ? theme.tabletM : theme.mobileL} {
        align-items: flex-end;
    }
`;

export const BottomSheetLayoutContainer = styled.div<{
    vh: number;
    isTabletMBottomSheet: boolean;
}>`
    position: fixed;
    background: #fff;
    box-shadow: 0 8px 24px rgba(83, 83, 83, 0.12),
        0 1px 3px rgba(36, 36, 36, 0.12);
    border-radius: 16px;
    max-width: 733px;
    ${(props) => props.theme.zIndexModalOrLayer};

    @media ${({ theme, isTabletMBottomSheet }) =>
            isTabletMBottomSheet ? theme.tabletM : theme.mobileL} {
        left: 0;
        bottom: 0;
        width: 100%;
        border-radius: 16px 16px 0 0;
        max-width: none;
        max-height: ${({ vh }) => "calc(" + 100 * vh + "px - 104px)"};
        opacity: 1;
    }
`;

export const BottomSheetLayoutContentsWrapper = styled.div<{
    vh: number;
    isTabletMBottomSheet: boolean;
    isNoMobileDefaultPadding: boolean;
}>`
    padding: ${({ isNoMobileDefaultPadding }) =>
        isNoMobileDefaultPadding ? 0 : "24px"};
    width: ${({ isNoMobileDefaultPadding }) =>
        isNoMobileDefaultPadding ? "100%" : "calc(100% - 48px)"};

    overflow-y: auto;
    max-height: ${({ vh }) => "calc(" + 100 * vh + "px - 104px)"};

    @media ${({ theme, isTabletMBottomSheet }) =>
            isTabletMBottomSheet ? theme.tabletM : theme.mobileL} {
        padding: ${({ isNoMobileDefaultPadding }) =>
            isNoMobileDefaultPadding ? 0 : "0 16px 16px 16px"};
        width: ${({ isNoMobileDefaultPadding }) =>
            isNoMobileDefaultPadding ? "auto" : "calc(100% - 32px)"};
    }

    max-height: ${({ vh }) => "calc(" + 100 * vh + "px - 120px)"};
`;

export const BottomSheetLayoutSlideHandlerAreaContainer = styled.div<{
    vh: number;
    isTabletMBottomSheet: boolean;
}>`
    display: none;
    @media ${({ theme, isTabletMBottomSheet }) =>
            isTabletMBottomSheet ? theme.tabletM : theme.mobileL} {
        position: absolute;
        top: 0;
        padding: 0 24px;
        width: calc(100% - 48px);
        height: 44px;
        border-radius: 16px 16px 0 0;
        display: flex;
        justify-content: center;
        background: white;
        z-index: 20;
    }
`;

export const BottomSheetLayoutSlideHandlerArea = styled.div`
    width: 40px;
    height: 4px;
    background: rgba(176, 176, 176, 0.4);
    border-radius: 2px;
    margin-top: 12px;
    cursor: pointer;
`;

export const BottomSheetLayoutContentsContainer = styled.div<{
    isTabletMBottomSheet: boolean;
}>`
    @media ${({ theme, isTabletMBottomSheet }) =>
            isTabletMBottomSheet ? theme.tabletM : theme.mobileL} {
        margin-top: 44px;
    }
`;

type Props = {
    modalId: string;
    modalResolve: (value: any) => void;
    children: ReactElement;
};

const BottomSheetLayout = (props: Props): ReactElement | null => {
    const { width } = useWindowSize();
    const [vh] = useAtom(viewHeightAtom);
    const setIsScrollLock = useSetAtom(isScrollLockAtom);
    const [isDrag, setIsDrag] = useState(false);
    const [dragStartPoint, setDragStartPoint] = useState(0);
    const [deltaDragValue, setDeltaDragValue] = useState(0);
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const dragStart = (clientY: number) => {
        setIsDrag(true);
        setDragStartPoint(clientY);
    };

    const dragging = (deltaClientY: number) => {
        if (isDrag) {
            if (dragStartPoint - deltaClientY < 0) {
                setDeltaDragValue(dragStartPoint - deltaClientY);
            }

            if (dragStartPoint - deltaClientY < -150) {
                props.modalResolve(false);
                dragEnd();
            }
        }
    };

    const isTabletMBottomSheet =
        props.modalId === "mobile-side-bar" ||
        props.modalId === "modal-type-search-filter";

    const isNoDefaultPadding =
        props.modalId === "mobile-side-bar" ||
        props.modalId === "modal-type-search-filter" ||
        props.modalId === "ad-sell-history-modal" ||
        props.modalId === "market-item-holder-list" ||
        props.modalId === "collection-creator-modal" ||
        props.modalId === "collection-follower-modal" ||
        props.modalId === "offer-select-modal";
    const dragEnd = () => {
        setIsDrag(false);
        setDragStartPoint(0);
        setDeltaDragValue(0);
    };

    const bottomSheetLayoutContentsContainer = useRef(null);

    useOnClickOutside(bottomSheetLayoutContentsContainer, () => {
        if (
            modalComponentInfoList.length > 0 &&
            modalComponentInfoList[modalComponentInfoList.length - 1].id ===
                props.modalId
        )
            props.modalResolve(false);
    });

    return (
        <BottomSheetLayoutWrapper>
            <BottomSheetLayoutBackground
                className={"bottom-sheet-layout__fade"}
                vh={vh}
                onMouseUp={dragEnd}
                onTouchEnd={dragEnd}
                onMouseMove={(e) => {
                    dragging(e.clientY);
                }}
                onTouchMove={(e) => {
                    dragging(e.touches[0].clientY);
                }}
                isTabletMBottomSheet={isTabletMBottomSheet}
                onClick={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                }}
            >
                <BottomSheetLayoutContainer
                    className={
                        width !== 0 &&
                        (isTabletMBottomSheet
                            ? width <= ResponsiveWidth.tabletL
                            : width <= ResponsiveWidth.mobileL)
                            ? "bottom-sheet-layout__slide"
                            : "bottom-sheet-layout__fade"
                    }
                    ref={bottomSheetLayoutContentsContainer}
                    vh={vh}
                    style={{
                        bottom:
                            width !== 0 && width <= ResponsiveWidth.mobileL
                                ? deltaDragValue + "px"
                                : "auto",
                    }}
                    isTabletMBottomSheet={isTabletMBottomSheet}
                >
                    <BottomSheetLayoutSlideHandlerAreaContainer
                        vh={vh}
                        onMouseDown={(e) => {
                            dragStart(e.clientY);
                        }}
                        onTouchStart={(e) => {
                            dragStart(e.touches[0].clientY);
                        }}
                        isTabletMBottomSheet={isTabletMBottomSheet}
                    >
                        <BottomSheetLayoutSlideHandlerArea
                            onClick={() => {
                                props.modalResolve(false);
                            }}
                        />
                    </BottomSheetLayoutSlideHandlerAreaContainer>
                    <BottomSheetLayoutContentsWrapper
                        vh={vh}
                        isTabletMBottomSheet={isTabletMBottomSheet}
                        isNoMobileDefaultPadding={isNoDefaultPadding}
                    >
                        <BottomSheetLayoutContentsContainer
                            isTabletMBottomSheet={isTabletMBottomSheet}
                        >
                            {props.children}
                        </BottomSheetLayoutContentsContainer>
                    </BottomSheetLayoutContentsWrapper>
                </BottomSheetLayoutContainer>
            </BottomSheetLayoutBackground>
        </BottomSheetLayoutWrapper>
    );
};

export default BottomSheetLayout;

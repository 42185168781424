export type AdInfoSalesType = {
    ad_idx: string;
    ad_minimum_bid_price: number;
    ad_reserve_price: number;
    ad_sales_idx: string;
    brand_royalty: number;
    buy_now_available_bool: boolean;
    created_at: string;
    creator_royalty: number;
    days_to_adjust_after_ad: number;
    deleted_at: null | string;
    service_country: string;
    updated_at: string;
    user_royalty: number;
    campaign_id: string;
    campaign_idx: string;
    campaign_type: string;
};

export const DEFAULT_ADVERTISEMENT_INFO_SALES: AdInfoSalesType = {
    ad_idx: "",
    ad_minimum_bid_price: 0,
    ad_reserve_price: 0,
    ad_sales_idx: "",
    brand_royalty: 0,
    buy_now_available_bool: false,
    created_at: "",
    creator_royalty: 0,
    days_to_adjust_after_ad: 0,
    deleted_at: null,
    service_country: "",
    updated_at: "",
    user_royalty: 0,
    campaign_id: "",
    campaign_idx: "",
    campaign_type: "",
};

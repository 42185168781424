import { ReactElement } from "react";
import styled from "styled-components";
import { AdBidCalendarCellCommonProps } from "../../useGetAdBidCalendarCellComponentByCode";
import { useAdInfoQuery } from "../../../../../../../../../../../query/ad/ad/useAdInfoQuery";
import { useGetAdDailyDateData } from "../../../../../../../../../../../hooks/ad/useGetAdDailyDateData";
import { numberToStringWithComma } from "../../../../../../../../../../../utils/Utils";
import CalendarSelectedMobileCelleKeyPrimaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCelleKeyPrimaryRow";
import CalendarSelectedMobileCellSecondaryRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellSecondaryRow";
import CalendarSelectedMobileCellBlueRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellBlueRow";
import { DEFAULT_ADVERTISEMENT_INFO_SALES } from "../../../../../../../../../../../types/ad/advertisementInfo/AdInfoSalesType";
import CalendarSelectedMobileCellDangerRow from "../../../../../../../../../../common/commonUiComponent/calendar/calenderSelectedMobileCellRow/CalendarSelectedMobileCellDangerRow";
import { HiddenKey } from "../../adBidCalendarCellBody/AdBidCalendarCellBody";

export const AdBidCalendarSelectedMobileCellBidClosedAndPreparingContainer = styled.div``;

type Props = {};

const AdBidCalendarSelectedMobileCellOnSchedule = (
    props: AdBidCalendarCellCommonProps,
): ReactElement | null => {
    const {
        data: rawAdData,
        isLoading: isAdDataLoading,
        isSuccess: isAdDataSuccess,
        error: errorAdData,
    } = useAdInfoQuery(props.adIdx);
    const adInfo = rawAdData.advertisementInfo;
    const adSalesInfo =
        adInfo.advertisement_sales || DEFAULT_ADVERTISEMENT_INFO_SALES;

    const {
        targetDateInfo: cellInfo,
        myOfferInfo,
        myOfferSuccessInfo,
        otherOfferInfo,
        otherOfferSuccessInfo,
        isTodaySale,
        isAdStop,
    } = useGetAdDailyDateData(props);

    return isTodaySale ? (
        myOfferSuccessInfo ? (
            // 당일 판매중 - 전체 판매 중단 상관X - 낙찰 표기
            <>
                <CalendarSelectedMobileCelleKeyPrimaryRow
                    keyMsg={"내 광고 게재중"}
                    valueMsg={`${numberToStringWithComma(
                        myOfferSuccessInfo.offer_price,
                    )} KRW`}
                />
                <HiddenKey
                    id={"AD_ON_SCHEDULE__dailySale__myOfferSuccessInfo__ms"}
                />
            </>
        ) : otherOfferSuccessInfo ? (
            <>
                <CalendarSelectedMobileCellSecondaryRow
                    keyMsg={"광고 게재중"}
                    valueMsg={`${numberToStringWithComma(
                        otherOfferSuccessInfo.offer_price,
                    )} KRW`}
                />
                <HiddenKey
                    id={"AD_ON_SCHEDULE__dailySale__otherOfferSuccessInfo__ms"}
                />
            </>
        ) : !isAdStop ? (
            // 당일 판매중 - 전체 판매중 - 낙찰X
            <>
                <CalendarSelectedMobileCellBlueRow
                    keyMsg={"즉시구매 가능"}
                    valueMsg={`${numberToStringWithComma(
                        adSalesInfo.ad_reserve_price * 1.1,
                    )} KRW`}
                />
                <HiddenKey
                    id={
                        "AD_ON_SCHEDULE__notAdStop__dailySale__noOfferSuccess__ms"
                    }
                />
            </>
        ) : (
            // 당일 판매중 - 전체 판매 중단 - 낙찰X
            <>
                <HiddenKey
                    id={"AD_ON_SCHEDULE__AdStop__dailySale__noOfferSuccess__ms"}
                />
            </>
        )
    ) : !isAdStop ? (
        // 당일 판 매중단 - 전체 판매중 - 낙찰X
        <>
            <CalendarSelectedMobileCellDangerRow
                keyMsg={"판매 중단"}
                valueMsg={""}
            />
            <HiddenKey
                id={
                    "AD_ON_SCHEDULE__notAdStop__notDailySale__noOfferSuccess__ms"
                }
            />
        </>
    ) : (
        // 당일 판매 중단 - 전체 판매중 - 낙찰X
        <>
            <HiddenKey
                id={"AD_ON_SCHEDULE__AdStop__notDailySale__noOfferSuccess__ms"}
            />
        </>
    );
};

export default AdBidCalendarSelectedMobileCellOnSchedule;

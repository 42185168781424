import { ReactElement } from "react";
import styled from "styled-components";

export const CalendarCellMobileRowContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

export const CalendarCellMobileRowKey = styled.div`
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 18.2px */
    width: 100px;
    display: flex;
    align-items: center;
    gap: 6px;

    @media ${(props) => props.theme.mobileL} {
        font-size: 12px;
    }
`;

export const CalendarCellMobileRowKeyImg = styled.img`
    width: 18px;
    height: 18px;
`;

export const CalendarCellMobileRowValue = styled.div`
    color: #555;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */

    @media ${(props) => props.theme.mobileL} {
        font-size: 12px;
    }
`;

export const CalendarCellMobileRowBadge = styled.span`
    display: flex;
    padding: 3px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: #fa54fa;
    color: #fff;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 11px */
`;

type Props = {
    keyMsg: string;
    valueMsg: string;
    keyStyle?: any;
    valueStyle?: any;
    containerStyle?: any;
    badgeMsg?: string;
    iconPath?: string;
};

const CalendarSelectedMobileCellRow = (props: Props): ReactElement | null => {
    return (
        <CalendarCellMobileRowContainer style={props.containerStyle}>
            <CalendarCellMobileRowKey style={props.keyStyle}>
                {props.iconPath && (
                    <CalendarCellMobileRowKeyImg src={props.iconPath} />
                )}
                {props.keyMsg}
            </CalendarCellMobileRowKey>
            <CalendarCellMobileRowValue style={props.valueStyle}>
                {props.valueMsg}
            </CalendarCellMobileRowValue>
            {props.badgeMsg && (
                <CalendarCellMobileRowBadge>
                    {props.badgeMsg}
                </CalendarCellMobileRowBadge>
            )}
        </CalendarCellMobileRowContainer>
    );
};

export default CalendarSelectedMobileCellRow;

import { useOpenModal } from "../useOpenModal";
import AdBidingSelectTemplateModal, {
    AdBidingTemplateSelectResolveType,
} from "../../../components/ad/adBiding/components/AdBidingSelectTemplate/components/AdBidingSelectTemplateModal/AdBidingSelectTemplateModal";
import { AD_BIDING_SELECT_IMAGE_MODAL_ID } from "../../../components/ad/adBiding/components/AdBidingSelectTemplate/AdBidingSelectTemplate";

export function useOpenAdBidingSelectTemplateModal() {
    const { openModalWithReturnValue } = useOpenModal();

    const _openAdBidingSelectTemplateModal = async (
        campaignIdx: string,
        setSelectedTemplateIdx: (value: string) => void,
    ): Promise<AdBidingTemplateSelectResolveType> => {
        return await openModalWithReturnValue(
            AD_BIDING_SELECT_IMAGE_MODAL_ID,
            "bottomSheet",
            (resolve) => {
                return (
                    <AdBidingSelectTemplateModal
                        resolve={resolve}
                        campaignIdx={campaignIdx}
                        setSelectedTemplateIdx={setSelectedTemplateIdx}
                    />
                );
            },
        );
    };

    return {
        openAdBidingSelectTemplateModal: _openAdBidingSelectTemplateModal,
    };
}

import React, { ReactElement, useEffect, useRef } from "react";
import styled from "styled-components";
import "./transition/modal-transition.css";
import "./transition/bottom-sheet-transition.css";
import "./transition/search-result-transition.css";
import "./transition/noti-list-transition.css";
import "./transition/profile-menu-transition.css";
import { useAtom, useSetAtom } from "jotai";
import ModalComponentInfoListAtom from "../../../../store/ui/ModalComponentInfoListAtom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ModalLayout from "./components/ModalLayout";
import BottomSheetLayout from "./components/BottomSheetLayout";
import { useLocalStorage, useWindowSize } from "usehooks-ts";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import isScrollLockAtom from "../../../../store/scroll/isScrollLockAtom";

export const ModalProviderContainer = styled.div``;

export const StyledTransitionGroup = styled.div`
    display: flex;
`;

type Props = {};

const ModalProvider = (props: Props): ReactElement | null => {
    const navigate = useNavigate();
    const [modalComponentInfoList] = useAtom(ModalComponentInfoListAtom);
    const setIsScrollLock = useSetAtom(isScrollLockAtom);
    const modalComponentInfoListRef = useRef(modalComponentInfoList);
    // const [, popModalComponentInfo] = useAtom(asyncPopModalComponentInfo);
    // iOS 에서 뒤로가기를 통한 모달 닫히는 경우는 애니메이션이 동작하지 않아야함.
    const [, setIsBackBtnClicked] = useLocalStorage("isBackBtnClicked", false);

    const popStateHandler = async (event: any) => {
        // 모달 관련 뒤로가기
        setIsBackBtnClicked(true);
        try {
            // 뒤로가기로 모달을 닫은 경우.
            if (modalComponentInfoListRef.current.length - 1 >= 0) {
                modalComponentInfoListRef.current[
                    modalComponentInfoListRef.current.length - 1
                ].resolve(-1);
            }
        } catch (e) {
            console.info("pop state modal error", e);
        }

        setIsScrollLock(false);
        if (window.modalClosingByClickResolver) {
            window.modalClosingByClickResolver();
        }

        setTimeout(() => {
            setIsBackBtnClicked(false);
        }, 500);
    };

    useEffect(() => {
        window.addEventListener("popstate", popStateHandler);

        return () => {
            window.removeEventListener("popstate", popStateHandler);
        };
    }, []);

    useEffect(() => {
        modalComponentInfoListRef.current = modalComponentInfoList;
        if (modalComponentInfoListRef.current.length > 0) {
            setIsScrollLock(true);
        } else {
            setIsScrollLock(false);
        }
    }, [modalComponentInfoList]);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                try {
                    if (modalComponentInfoListRef.current.length > 0) {
                        modalComponentInfoListRef.current[
                            modalComponentInfoListRef.current.length - 1
                        ].resolve(false);
                    }
                } catch (e) {
                    console.info("pop state modal error", e);
                }
            }
        };
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);
    const location = useLocation();
    const { width } = useWindowSize();

    return (
        <ModalProviderContainer>
            {/*
                여기서 length 로 안하면 router 변경을 통한 lazy load 로 router 바깥 component node 들이 기억되는 버그? 가 있음
                그래서 갯수가 0이 되서 사라진걸로 치면 아예 노드들을 지워버려야함.
                근데 지우게되면 나타날 때 애니메이션이 적용이 안됨.
                isShowAnimated false 인상태로 들어와서 생성 직후에 true 로 변경

                사라지는 애니메이션도 라우팅시에 잔존하는 버그가 있음. 마찬가지로 0이되면 사라지게는 하되
                inResolve 가 돌아서 삭제 할 때, isShowAnimated true => false 로 변경하게 한다음 애니메이션을 동작시키고
                0.25초 애니메이션이 끝나고 0.26초 타이밍에 삭제. 
            */}
            {modalComponentInfoList.length !== 0 && (
                <TransitionGroup>
                    {modalComponentInfoList.map((modalComponentInfo, idx) => {
                        if (modalComponentInfo.type !== "onlyHistory") {
                            if (modalComponentInfo.type !== "self-transition") {
                                return modalComponentInfo.isShowAnimated ? (
                                    <CSSTransition
                                        key={modalComponentInfo.id}
                                        timeout={250}
                                        classNames={
                                            modalComponentInfo.type === "modal"
                                                ? "fade"
                                                : "bottom-sheet-slide-up"
                                        }
                                    >
                                        {modalComponentInfo.type === "modal" ? (
                                            <ModalLayout
                                                modalId={modalComponentInfo.id}
                                                modalResolve={
                                                    modalComponentInfo.resolve
                                                }
                                                children={modalComponentInfo.renderComponent()}
                                            />
                                        ) : (
                                            <BottomSheetLayout
                                                modalId={modalComponentInfo.id}
                                                modalResolve={
                                                    modalComponentInfo.resolve
                                                }
                                                children={modalComponentInfo.renderComponent()}
                                            />
                                        )}
                                    </CSSTransition>
                                ) : null;
                            }
                            //
                            else {
                                return modalComponentInfo.isShowAnimated ? (
                                    <CSSTransition
                                        key={modalComponentInfo.id}
                                        timeout={250}
                                        nodeRef={modalComponentInfo.nodeRef}
                                        classNames={
                                            modalComponentInfo.transitionClassName
                                        }
                                    >
                                        {modalComponentInfo.renderComponent()}
                                    </CSSTransition>
                                ) : null;
                            }
                        } else {
                            return null;
                        }
                    })}
                </TransitionGroup>
            )}
        </ModalProviderContainer>
    );
};

export default ModalProvider;
